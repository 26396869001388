import { initBasketForm } from '../features/basket';
import { initChoosersForContainer } from '../pdp/chooser/chooser-helpers';
import { productOptionChanged } from '../pdp/chooser/constants';
import {
  dispatchResultsRendered,
  initSwatches,
  reloadResult,
  resultRendered,
} from './result';
import 'scheduler-polyfill';

export const initEmbeddedListing = ({ container }) => {
  const expandedResultPositions = {};

  // Add an event listener that initialises swatches when a result card is
  // rendered.
  container.addEventListener(resultRendered, e => {
    const resultCard = e.detail.result;
    initSwatches(resultCard);
    initChoosersForContainer(resultCard);

    const basketForm = resultCard.querySelector('.js-add-to-basket-form');
    if (basketForm) {
      initBasketForm(basketForm);
    }

    // Initialise expandable containers.
    resultCard.querySelectorAll('.expander').forEach(expander => {
      // Set the initial state of the expander.
      const previouslyExpanded =
        expandedResultPositions[resultCard.dataset.position] ?? false;
      expander.classList.toggle('expander--expanded-a', previouslyExpanded);
      expander.classList.toggle('expander--expanded-b', previouslyExpanded);

      // Add an event listener that toggles the expander.
      // Use delayed class remove to allow granular control over the animation
      expander
        .querySelector('.expander__toggle')
        .addEventListener('click', () => {
          const expanded = () => {
            if (expander.classList.contains('expander--expanded-b')) {
              expander.classList.remove('expander--expanded-b');

              setTimeout(() => {
                expander.classList.remove('expander--expanded-a');
              }, 250);
            } else {
              expander.classList.add('expander--expanded-a');

              setTimeout(() => {
                expander.classList.add('expander--expanded-b');
                expandedResultPositions[resultCard.dataset.position] = true;
              }, 250);
            }
          };

          expandedResultPositions[resultCard.dataset.position] = expanded();
        });
    });
  });

  // Add an event listener that reloads the result card when a product option
  // is changed.
  container.addEventListener(productOptionChanged, e => {
    e.stopPropagation();
    const { optionValues } = e.detail;

    const resultCard = e.target.closest('.listing-item');

    // Load the result in a separate task, to avoid blocking the main thread.
    scheduler.postTask(() => reloadResult(resultCard, optionValues), {
      priority: 'background',
    });
  });

  // Dispatch an event for all the result cards that are already rendered, to
  // initialise the options.
  dispatchResultsRendered(container);
};
